import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import ContentRouter from './content-router';
import Menu from './menu';
import TopBar from './top-bar';
import Auth from '../../services/auth';

class MainApp extends React.Component {
  constructor(props) {
    super(props);

    this.auth = new Auth();
    this.state = {
      inProgress: false
    };

    this.signOut = this.signOut.bind(this);
  }

  async componentDidMount() {
    if (!this.props.user) {
      this.props.onSignOut();
      return;
    }
  }

  signOut() {
    if (this.state.inProgress) {
      return;
    }

    this.setState(
      {
        inProgress: true
      },
      async () => {
        try {
          await this.auth.signOut();
        } catch (err) {
          console.log(err);
          this.setState({
            inProgress: false
          });
          return;
        }

        this.props.onSignOut();
      }
    );
  }

  render() {
    return (
      <>
        {this.state.inProgress ? (
          <Container className="app-container">
            <Spinner animation="border" />
          </Container>
        ) : (
          <Container fluid className="fluid-max">
            <TopBar title="Team productivity" signOut={this.signOut} />
            <Container fluid>
              <Row>
                <Col md="2">
                  <Menu />
                </Col>
                <Col md="10">
                  <ContentRouter />
                </Col>
              </Row>
            </Container>
          </Container>
        )}
      </>
    );
  }
}

MainApp.propTypes = {
  user: PropTypes.object,
  onSignOut: PropTypes.func
};

export default MainApp;
