import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import history from '../../services/history';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        {
          path: '/',
          title: 'Last week'
        },
        {
          path: '/history',
          title: 'History'
        },
        {
          path: '/bonus_history',
          title: 'Bonus history'
        }
      ]
    };
  }

  render() {
    return (
      <Nav bg="light" variant="light" className="d-none d-md-block sidebar">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center mt-4 mb-1 text-muted">
          <span>Team Reports</span>
        </h6>
        <Nav as="ul">
          {this.state.items.map((item) => {
            return <MenuLink key={item.title} path={item.path} title={item.title} />;
          })}
        </Nav>
      </Nav>
    );
  }
}

class MenuLink extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.stopPropagation();

    history.push(this.props.path);
  }

  render() {
    return (
      <Nav.Item as="li">
        <Nav.Link onClick={this.onClick}>
          <Icon className="feather" /> <span>{this.props.title}</span>
        </Nav.Link>
      </Nav.Item>
    );
  }
}

MenuLink.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string
};

class Icon extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-file-text"
      >
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
        <polyline points="14 2 14 8 20 8"></polyline>
        <line x1="16" y1="13" x2="8" y2="13"></line>
        <line x1="16" y1="17" x2="8" y2="17"></line>
        <polyline points="10 9 9 9 8 9"></polyline>
      </svg>
    );
  }
}

export default Menu;
