import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import isEmail from 'validator/lib/isEmail';
import debounce from 'lodash/debounce';

import Auth from '../../services/auth';

import './style.css';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',

      isEmailValid: false,
      isEmailDirty: false,
      isPasswordValid: false,
      isPasswordDirty: false,

      errorMessage: '',

      isFormValid: false,
      isFormInProgress: false,

      isLoaded: false
    };

    this.handleInput = this.handleInput.bind(this);
    this.submit = this.submit.bind(this);

    this.validateInputDebounced = debounce(this.validateInput, 250);

    this.auth = new Auth();
  }

  componentWillUnmount() {
    this.validateInputDebounced.cancel();
  }

  clearErrorMessage() {
    this.setState({
      errorMessage: ''
    });
  }

  validateInput(name, value) {
    switch (name) {
      case 'email':
        this.setState(
          {
            isEmailDirty: true,
            isEmailValid: value && isEmail(value)
          },
          () => {
            this.updateFormValidation();
          }
        );
        break;

      case 'password':
        this.setState(
          {
            isPasswordDirty: true,
            isPasswordValid: value && value.length >= 6
          },
          () => {
            this.updateFormValidation();
          }
        );
        break;
    }
  }

  updateFormValidation() {
    this.setState({
      isFormValid: this.state.isEmailValid && this.state.isPasswordValid
    });
  }

  handleInput(event) {
    if (this.state.errorMessage !== '') {
      this.clearErrorMessage();
    }

    const name = event.target.name;

    this.setState(
      {
        [name]: event.target.value
      },
      () => {
        this.validateInputDebounced(name, this.state[name]);
      }
    );
  }

  async submit(event) {
    event.preventDefault();
    if (this.state.isFormInProgress) {
      return;
    }

    if (this.state.errorMessage !== '') {
      this.clearErrorMessage();
    }

    if (!this.state.isEmailDirty) {
      this.setState({
        isEmailDirty: true
      });
    }

    if (!this.state.isPasswordDirty) {
      this.setState({
        isPasswordDirty: true
      });
    }

    if (!this.state.isFormValid) {
      return;
    }

    this.setState(
      {
        isFormInProgress: true
      },
      async () => {
        try {
          const res = await this.auth.signIn(this.state.email, this.state.password);
          this.props.onSuccess(res);
        } catch (err) {
          this.setState({
            errorMessage: err.message || 'Unknown error'
          });

          this.setState({
            isFormInProgress: false
          });
        }
      }
    );
  }

  render() {
    return (
      <Container className="app-container">
        <Form className="sign-in-form" onSubmit={this.submit}>
          {this.state.errorMessage !== '' && <Alert variant="danger">{this.state.errorMessage}</Alert>}

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.handleInput}
              readOnly={this.state.isFormInProgress}
            ></Form.Control>
            {this.state.isEmailDirty && !this.state.isEmailValid && <p className="text-danger">Invalid email</p>}
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleInput}
              readOnly={this.state.isFormInProgress}
            ></Form.Control>
            {this.state.isPasswordDirty && !this.state.isPasswordValid && (
              <p className="text-danger">Password length must be at least 6 symbols</p>
            )}
          </Form.Group>

          <Button variant="primary" type="submit" onClick={this.submit} disabled={this.state.isFormInProgress}>
            {this.state.isFormInProgress ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="mar-r-10" />
                Signing in...
              </>
            ) : (
              'Sign in'
            )}
          </Button>
        </Form>
      </Container>
    );
  }
}

SignIn.propTypes = {
  onSuccess: PropTypes.func
};

export default SignIn;
