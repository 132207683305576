import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import PeriodItem from './period_item.component';
import { LimitType, PeriodItemType } from './types';

class PeriodTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Table bordered="true" hover="true" striped="true" className="m-bot-50">
        <thead>
          <tr>
            <th></th>
            <th>Primary tasks</th>
            <th>Daily fix</th>
            <th>QA</th>
            <th>KB</th>
            <th>Gitlab contributions</th>
            <th>Penalty</th>
            <th>Total</th>
            <th>Bonus</th>
          </tr>
        </thead>
        <tbody>
          {this.props.items.map((v) => {
            return (
              <PeriodItem
                key={v.name}
                name={v.name}
                primary={v.primary}
                daily_fix={v.daily_fix}
                qa={v.qa}
                kb={v.kb}
                gitlab={v.gitlab}
                penalty={v.penalty}
                total={v.total}
                bonus={v.bonus}
                skip={v.skip}
                max={this.props.total}
                limit={this.props.limit}
              />
            );
          })}
        </tbody>
      </Table>
    );
  }
}

PeriodTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(PeriodItemType)),
  total: LimitType,
  limit: LimitType
};

export default PeriodTable;
