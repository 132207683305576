import md5 from 'crypto-js/md5';

const users = {
  'dev@orion619.com': '4297f44b13955235245b2497399d7a93'
};

function sleep(timeInMs = 500) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeInMs);
  });
}

class Auth {
  async signIn(email, password) {
    await sleep(1000);

    if (users[email] !== md5(password).toString()) {
      throw new Error('Invalid email or password');
    }

    try {
      localStorage.setItem('email', email);
    } catch (err) {
      console.log(err);
    }

    return {
      email: email
    };
  }

  async checkAuth() {
    await sleep(1000);

    let email;
    try {
      email = localStorage.getItem('email');
    } catch (err) {
      console.log(err);
    }

    if (!email || !users[email]) {
      return null;
    }

    return {
      email: email
    };
  }

  async signOut() {
    await sleep(1000);

    try {
      localStorage.removeItem('email');
    } catch (err) {
      console.log(err);
    }
  }
}

export default Auth;
