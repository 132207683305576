import React from 'react';
import './App.css';
import SignIn from './components/sign-in';
import MainApp from './components/main-app';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import Auth from './services/auth';
import history from './services/history';
import { Container, Spinner } from 'react-bootstrap';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      isLoaded: false
    };

    this.onSignIn = this.onSignIn.bind(this);
    this.onSignOut = this.onSignOut.bind(this);

    this.auth = new Auth();
  }

  async componentDidMount() {
    const user = await this.auth.checkAuth();

    this.setState({
      user: user,
      isLoaded: true
    });
  }

  onSignIn(user) {
    this.setState(
      {
        user: user
      },
      () => {
        history.push('/');
      }
    );
  }

  onSignOut() {
    this.setState(
      {
        user: null
      },
      () => {
        history.push('/login');
      }
    );
  }

  render() {
    return (
      <>
        {this.state.isLoaded ? (
          <Router history={history}>
            <Switch>
              <Route path="/login">
                {this.state.user ? <Redirect to="/" /> : <SignIn onSuccess={this.onSignIn} />}
              </Route>
              <Route path="*">
                {this.state.user ? (
                  <MainApp onSignOut={this.onSignOut} user={this.state.user} />
                ) : (
                  <Redirect to="/login" />
                )}
              </Route>
            </Switch>
          </Router>
        ) : (
          <Container className="app-container">
            <Spinner animation="border" />
          </Container>
        )}
      </>
    );
  }
}

export default App;
