import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import TotalItem from './total_item.component';
import { TotalItemType } from './types';

class TotalTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Table bordered="true" hover="true" striped="true" className="m-bot-50">
        <thead>
          <tr>
            <th></th>
            <th>Primary tasks</th>
            <th>Daily fix</th>
            <th>QA</th>
            <th>KB</th>
            <th>Gitlab contributions</th>
            <th>Bonus</th>
          </tr>
        </thead>
        <tbody>
          {this.props.items.map((v) => {
            return <TotalItem key={v.name} {...v} />;
          })}
        </tbody>
      </Table>
    );
  }
}

TotalTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(TotalItemType))
};

export default TotalTable;
