import PropTypes from 'prop-types';

const LimitType = PropTypes.shape({
  primary: PropTypes.number,
  daily_fix: PropTypes.number,
  qa: PropTypes.number,
  kb: PropTypes.number,
  gitlab: PropTypes.number
});

const PeriodItemType = {
  name: PropTypes.string,
  primary: PropTypes.number,
  daily_fix: PropTypes.number,
  qa: PropTypes.number,
  kb: PropTypes.number,
  gitlab: PropTypes.number,
  penalty: PropTypes.number,
  total: PropTypes.number,
  bonus: PropTypes.number,
  max: LimitType,
  skip: PropTypes.bool
};

export { PeriodItemType, LimitType };
