import React from 'react';
import PeriodTable from '../period_table';

class History extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        [
          { name: '23.08-29.08.2021', gitlab: 50, daily_fix: 6, qa: 5, primary: 1, kb: 1, total: 5, bonus: 13500 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 52, kb: 0, total: 0.5, bonus: 1000 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 9,
            primary: 1,
            qa: 0,
            gitlab: 32,
            kb: 0,
            total: 1.5,
            bonus: 5000
          },
          { name: 'Trian', daily_fix: 4, primary: 0, qa: 0, gitlab: 56, kb: 0, total: 0.5, bonus: 1000 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 9, gitlab: 0, kb: 1, total: 2, bonus: 4500 },
          {
            name: 'Patrick',
            daily_fix: 0,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Theo',
            daily_fix: 5,
            primary: 1,
            qa: 0,
            gitlab: 93,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '16.08-22.08.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 2, primary: 2, qa: 0, gitlab: 70, kb: 0, total: 1.33, bonus: 3350 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 3,
            primary: 1,
            qa: 0,
            gitlab: 20,
            kb: 0,
            total: 0.33,
            bonus: 1350
          },
          { name: 'Trian', daily_fix: 7, primary: 0, qa: 0, gitlab: 45, kb: 0, total: 1, bonus: 3000 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 2, gitlab: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 1, total: 1, bonus: 2000 },
          {
            name: 'Patrick',
            daily_fix: 2,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.33,
            bonus: 1350
          },
          {
            name: 'Theo',
            daily_fix: 2,
            primary: 1,
            qa: 0,
            gitlab: 130,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '09.08-15.08.2021', gitlab: 50, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 5, primary: 1, qa: 0, gitlab: 34, kb: 0, total: 0.5, bonus: 2000 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 4,
            primary: 0,
            qa: 0,
            gitlab: 21,
            kb: 0,
            total: 0,
            bonus: 0
          },
          { name: 'Trian', daily_fix: 9, primary: 1, qa: 0, gitlab: 58, kb: 0, total: 2.5, bonus: 7000 },
          { name: 'Lev', daily_fix: 4, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 2, gitlab: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 2, total: 1.5, bonus: 3250 },
          {
            name: 'Patrick',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Theo',
            daily_fix: 1,
            primary: 0,
            qa: 0,
            gitlab: 9,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '02.08-08.08.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 1, primary: 0, qa: 0, gitlab: 33, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 2,
            primary: 2,
            qa: 0,
            gitlab: 1,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Aleksey',
            daily_fix: 2,
            primary: 0,
            qa: 0,
            gitlab: 13,
            kb: 0,
            total: 0,
            bonus: 0
          },
          { name: 'Trian', daily_fix: 6, primary: 0, qa: 0, gitlab: 36, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Lev', daily_fix: 4, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 1, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 2, total: 1, bonus: 2000 },
          {
            name: 'Patrick',
            daily_fix: 8,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 1,
            bonus: 3500
          },
          {
            name: 'Theo',
            daily_fix: 8,
            primary: 1,
            qa: 0,
            gitlab: 117,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '26.07-01.08.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 1,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 6,
            primary: 0,
            qa: 0,
            gitlab: 9,
            kb: 0,
            total: 1,
            bonus: 3000
          },
          { name: 'Trian', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', daily_fix: 4, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 0, total: 0, bonus: 2500 },
          { name: 'Maria', daily_fix: 2, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 1, bonus: 2000 },
          {
            name: 'Patrick',
            daily_fix: 4,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 2,
            total: 0,
            bonus: 0
          },
          {
            name: 'Theo',
            daily_fix: 5,
            primary: 0,
            qa: 0,
            gitlab: 67,
            kb: 3,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '19.07-25.07.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 2,
            qa: 0,
            gitlab: 6,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Aleksey',
            daily_fix: 8,
            primary: 0,
            qa: 0,
            gitlab: 113,
            kb: 0,
            total: 1.5,
            bonus: 3500
          },
          { name: 'Trian', daily_fix: 2, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', daily_fix: 9, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 2, gitlab: 0, kb: 2, total: 1.5, bonus: 3250 },
          {
            name: 'Patrick',
            daily_fix: 0,
            primary: 2,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Theo',
            daily_fix: 8,
            primary: 1,
            qa: 0,
            gitlab: 58,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '12.07-18.07.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 1, qa: 0, gitlab: 11, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 2,
            primary: 3,
            qa: 0,
            gitlab: 4,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Aleksey',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 73,
            kb: 0,
            total: 1,
            bonus: 2000
          },
          {
            name: 'Trian',
            daily_fix: 11,
            primary: 1,
            qa: 0,
            gitlab: 52,
            kb: 0,
            total: 0.5,
            bonus: 1500
          },
          { name: 'Lev', daily_fix: 5, primary: 1, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 1, total: 1.5, bonus: 3250 },
          {
            name: 'Patrick',
            daily_fix: 7,
            primary: 2,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 1,
            bonus: 3500
          },
          {
            name: 'Theo',
            daily_fix: 1,
            primary: 1,
            qa: 0,
            gitlab: 29,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '05.07-11.07.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 9, primary: 1, qa: 0, gitlab: 75, kb: 0, total: 1.5, bonus: 4500 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 4,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 2,
            primary: 0,
            qa: 0,
            gitlab: 79,
            kb: 0,
            total: 0.5,
            bonus: 1000
          },
          {
            name: 'Trian',
            daily_fix: 12,
            primary: 0,
            qa: 0,
            gitlab: 53,
            kb: 0,
            total: 0.5,
            bonus: 1500
          },
          { name: 'Lev', daily_fix: 1, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Patrick',
            daily_fix: 0,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Theo',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 4,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '28.06-04.07.2021', gitlab: 50, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 53, kb: 0, total: 0.5, bonus: 1000 },
          {
            name: 'Bonyaka',
            daily_fix: 2,
            primary: 0,
            qa: 0,
            gitlab: 16,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          { name: 'Trian', daily_fix: 2, primary: 0, qa: 0, gitlab: 68, kb: 0, total: 0.5, bonus: 1000 },
          { name: 'Lev', daily_fix: 5, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 1, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 3, total: 1, bonus: 2000 },
          {
            name: 'Patrick',
            daily_fix: 10,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 1,
            bonus: 3000
          },
          {
            name: 'Theo',
            daily_fix: 6,
            primary: 0,
            qa: 0,
            gitlab: 99,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '21.06-27.06.2021', gitlab: 60, daily_fix: 5, qa: 2, primary: 2, kb: 1 },
          { name: 'Re', daily_fix: 10, primary: 2, qa: 0, gitlab: 72, kb: 0, total: 2.5, bonus: 7000 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 1,
            qa: 0,
            gitlab: 7,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 4,
            primary: 1,
            qa: 0,
            gitlab: 43,
            kb: 0,
            total: 0,
            bonus: 0
          },
          { name: 'Trian', daily_fix: 0, primary: 1, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', daily_fix: 3, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 4, gitlab: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 5, gitlab: 0, kb: 3, total: 1.5, bonus: 3250 },
          {
            name: 'Patrick',
            daily_fix: 1,
            primary: 3,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          {
            name: 'Theo',
            daily_fix: 2,
            primary: 1,
            qa: 0,
            gitlab: 61,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '14.06-20.06.2021', gitlab: 60, daily_fix: 5, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 62, kb: 0, total: 1, bonus: 2000 },
          {
            name: 'Bonyaka',
            daily_fix: 1,
            primary: 1,
            qa: 0,
            gitlab: 4,
            kb: 0,
            total: 1,
            bonus: 4000
          },
          {
            name: 'Aleksey',
            daily_fix: 2,
            primary: 0,
            qa: 0,
            gitlab: 28,
            kb: 0,
            total: 0,
            bonus: 0
          },
          { name: 'Trian', daily_fix: 7, primary: 0, qa: 0, gitlab: 43, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Lev', daily_fix: 5, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 1, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 1, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Patrick',
            daily_fix: 1,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Theo',
            daily_fix: 6,
            primary: 0,
            qa: 0,
            gitlab: 40,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '07.06-13.06.2021', gitlab: 60, daily_fix: 6, qa: 3, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 2,
            primary: 0,
            qa: 0,
            gitlab: 3,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 13,
            primary: 1,
            qa: 0,
            gitlab: 18,
            kb: 0,
            total: 1.33,
            bonus: 5000
          },
          { name: 'Trian', daily_fix: 9, primary: 0, qa: 0, gitlab: 64, kb: 0, total: 1.33, bonus: 3000 },
          { name: 'Lev', daily_fix: 1, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 5, gitlab: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 3, total: 1.5, bonus: 3250 },
          {
            name: 'Patrick',
            daily_fix: 9,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.33,
            bonus: 1000
          },
          {
            name: 'Theo',
            daily_fix: 8,
            primary: 0,
            qa: 0,
            gitlab: 48,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '31.05-06.06.2021', gitlab: 60, daily_fix: 6, qa: 4, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 5, primary: 0, qa: 0, gitlab: 63, kb: 0, total: 1, bonus: 2000 },
          {
            name: 'Bonyaka',
            daily_fix: 1,
            primary: 0,
            qa: 0,
            gitlab: 3,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 10,
            primary: 0,
            qa: 0,
            gitlab: 38,
            kb: 0,
            total: 1,
            bonus: 3000
          },
          { name: 'Trian', daily_fix: 4, primary: 1, qa: 0, gitlab: 44, kb: 0, total: 1, bonus: 3000 },
          { name: 'Lev', daily_fix: 2, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 4, gitlab: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 7, gitlab: 0, kb: 3, total: 1.5, bonus: 3250 },
          {
            name: 'Patrick',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Theo',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 28,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '24.05-30.05.2021', gitlab: 40, daily_fix: 6, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 1, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 4,
            primary: 0,
            qa: 0,
            gitlab: 35,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 2,
            primary: 1,
            qa: 0,
            gitlab: 30,
            kb: 0,
            total: 0.5,
            bonus: 2000
          },
          { name: 'Trian', daily_fix: 7, primary: 1, qa: 0, gitlab: 21, kb: 0, total: 1, bonus: 3500 },
          { name: 'Lev', daily_fix: 5, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 8, gitlab: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Patrick',
            daily_fix: 8,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.5,
            bonus: 1500
          },
          {
            name: 'Theo',
            daily_fix: 8,
            primary: 1,
            qa: 0,
            gitlab: 55,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '17.05-23.05.2021', gitlab: 60, daily_fix: 6, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 4, primary: 1, qa: 0, gitlab: 97, kb: 0, total: 0.58, bonus: 1670 },
          {
            name: 'Bonyaka',
            daily_fix: 3,
            primary: 0,
            qa: 0,
            gitlab: 4,
            kb: 0,
            total: 0,
            bonus: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 0,
            primary: 1,
            qa: 0,
            gitlab: 104,
            kb: 0,
            total: 0.58,
            bonus: 1670
          },
          { name: 'Trian', daily_fix: 8, primary: 0, qa: 0, gitlab: 82, kb: 0, total: 0.67, bonus: 1670 },
          { name: 'Lev', daily_fix: 6, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 9, gitlab: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', daily_fix: 0, primary: 1, qa: 2, gitlab: 0, kb: 0, total: 0.25, bonus: 1000 },
          {
            name: 'Patrick',
            daily_fix: 8,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.58,
            bonus: 2000
          },
          {
            name: 'Theo',
            daily_fix: 10,
            primary: 0,
            qa: 0,
            gitlab: 156,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '10.05-16.05.2021', gitlab: 60, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', daily_fix: 0, primary: 1, qa: 0, gitlab: 55, kb: 0, total: 0.25, bonus: 1000 },
          {
            name: 'Bonyaka',
            daily_fix: 3,
            primary: 1,
            qa: 0,
            gitlab: 9,
            kb: 0,
            total: 0.25,
            bonus: 1000
          },
          {
            name: 'Aleksey',
            daily_fix: 0,
            primary: 1,
            qa: 0,
            gitlab: 65,
            kb: 0,
            total: 0.75,
            bonus: 2000
          },
          { name: 'Trian', daily_fix: 9, primary: 0, qa: 0, gitlab: 79, kb: 0, total: 1, bonus: 2500 },
          { name: 'Lev', daily_fix: 10, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 7, gitlab: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 1, gitlab: 0, kb: 0, total: 0, bonus: 0 },
          {
            name: 'Patrick',
            daily_fix: 4,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0,
            total: 0.25,
            bonus: 1000
          },
          {
            name: 'Theo',
            daily_fix: 5,
            primary: 1,
            qa: 0,
            gitlab: 36,
            kb: 0,
            skip: true,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '03.05-09.05.2021', gitlab: 40, daily_fix: 5, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 40, daily_fix: 1, qa: 0, primary: 0, kb: 0, penalty: -3000, total: 0.5, bonus: 4000 },
          {
            name: 'Bonyaka',
            gitlab: 17,
            daily_fix: 0,
            qa: 0,
            primary: 0,
            kb: 0,
            penalty: -4000,
            total: 0,
            bonus: 4000
          },
          {
            name: 'Aleksey',
            gitlab: 24,
            daily_fix: 0,
            qa: 0,
            primary: 0,
            kb: 0,
            penalty: -2000,
            total: 0,
            bonus: 2000
          },
          {
            name: 'Trian',
            gitlab: 58,
            daily_fix: 10,
            qa: 0,
            primary: 0,
            kb: 0,
            penalty: -4000,
            total: 1.5,
            bonus: 8000
          },
          { name: 'Lev', gitlab: 8, daily_fix: 0, qa: 0, primary: 0, kb: 0, penalty: -1000, total: 0, bonus: 1000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 1, primary: 0, kb: 0, penalty: -2000, total: 0, bonus: 2000 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 3, primary: 0, kb: 1, penalty: -1000, total: 2, bonus: 5500 },
          { name: 'Patrick', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, penalty: -2000, total: 0, bonus: 2000 },
          {
            name: 'Theo',
            gitlab: 25,
            daily_fix: 2,
            qa: 0,
            primary: 0,
            kb: 0,
            skip: true,
            penalty: 0,
            total: 0,
            bonus: 0
          }
        ],
        [
          { name: '26.04-02.05.2021', gitlab: 60, daily_fix: 12, qa: 6, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 61, daily_fix: 5, qa: 0, primary: 0, kb: 0, total: 0.33, bonus: 670 },
          { name: 'Bonyaka', gitlab: 21, daily_fix: 16, qa: 0, primary: 0, kb: 0, total: 1, bonus: 3000 },
          { name: 'Aleksey', gitlab: 28, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 80, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 0.33, bonus: 670 },
          { name: 'Lev', gitlab: 74, daily_fix: 10, qa: 0, primary: 0, kb: 0, total: 0.33, bonus: 670 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 12, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 3, primary: 0, kb: 1, total: 1, bonus: 2000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 6, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 36, daily_fix: 2, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '19.04-25.04.2021', gitlab: 90, daily_fix: 6, qa: 6, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 58, daily_fix: 6, qa: 0, primary: 1, kb: 0, total: 0.83, bonus: 3000 },
          { name: 'Bonyaka', gitlab: 21, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 9, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 68, daily_fix: 9, qa: 0, primary: 1, kb: 0, total: 0.83, bonus: 3000 },
          { name: 'Lev', gitlab: 150, daily_fix: 6, qa: 0, primary: 0, kb: 0, total: 1.33, bonus: 3000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 15, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 57, daily_fix: 3, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '12.04-18.04.2021', gitlab: 90, daily_fix: 6, qa: 6, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 77, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 113, daily_fix: 6, qa: 0, primary: 2, kb: 0, total: 2, bonus: 5500 },
          { name: 'Aleksey', gitlab: 66, daily_fix: 6, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Trian', gitlab: 54, daily_fix: 1, qa: 0, primary: 3, kb: 0, total: 0.5, bonus: 2000 },
          { name: 'Lev', gitlab: 54, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 4, primary: 0, kb: 1, total: 1, bonus: 2000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 41, daily_fix: 5, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '05.04-11.04.2021', gitlab: 90, daily_fix: 7, qa: 6, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 75, daily_fix: 5, qa: 0, primary: 1, kb: 0, total: 0.25, bonus: 1000 },
          { name: 'Bonyaka', gitlab: 59, daily_fix: 6, qa: 0, primary: 2, kb: 0, total: 0.25, bonus: 1000 },
          { name: 'Aleksey', gitlab: 114, daily_fix: 5, qa: 0, primary: 2, kb: 0, total: 0.75, bonus: 2000 },
          { name: 'Trian', gitlab: 120, daily_fix: 1, qa: 0, primary: 1, kb: 0, total: 0.75, bonus: 2000 },
          { name: 'Lev', gitlab: 41, daily_fix: 10, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 19, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 2, total: 1, bonus: 2000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Theo', gitlab: 87, daily_fix: 13, qa: 0, primary: 0, kb: 0, skip: true }
        ],
        [
          { name: '29.03-04.04.2021', gitlab: 90, daily_fix: 7, qa: 6, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 131, daily_fix: 9, qa: 0, primary: 1, kb: 0, total: 1.75, bonus: 5750 },
          { name: 'Bonyaka', gitlab: 21, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0.25, bonus: 750 },
          { name: 'Aleksey', gitlab: 97, daily_fix: 10, qa: 0, primary: 0, kb: 0, total: 0.75, bonus: 1750 },
          { name: 'Trian', gitlab: 56, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 35, daily_fix: 5, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 4, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0.25, bonus: 750 },
          { name: 'Theo', gitlab: 38, daily_fix: 8, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '22.03-28.03.2021', gitlab: 100, daily_fix: 9, qa: 7, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 64, daily_fix: 5, qa: 0, primary: 1, kb: 0, total: 0.5, bonus: 2000 },
          { name: 'Bonyaka', gitlab: 33, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 171, daily_fix: 12, qa: 0, primary: 1, kb: 0, total: 2, bonus: 5500 },
          { name: 'Trian', gitlab: 63, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 79, daily_fix: 11, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 10, primary: 0, kb: 0, penalty: -1000, total: 1, bonus: 3500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 3, primary: 0, kb: 1, total: 1, bonus: 2000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 4, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 106, daily_fix: 14, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '15.03-21.03.2021', gitlab: 75, daily_fix: 9, qa: 7, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 33, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 67, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0.25, bonus: 750 },
          { name: 'Aleksey', gitlab: 103, daily_fix: 13, qa: 0, primary: 0, kb: 0, total: 1.25, bonus: 2750 },
          { name: 'Trian', gitlab: 58, daily_fix: 2, qa: 0, primary: 1, kb: 0, total: 1, bonus: 4000 },
          { name: 'Lev', gitlab: 72, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0.25, bonus: 750 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 10, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, kb: 2, total: 1, bonus: 2000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0.25, bonus: 750 },
          { name: 'Theo', gitlab: 69, daily_fix: 4, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '08.03-14.03.2021', gitlab: 70, daily_fix: 10, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 15, daily_fix: 15, qa: 0, primary: 0, kb: 0, total: 1, bonus: 3000 },
          { name: 'Bonyaka', gitlab: 29, daily_fix: 4, qa: 0, primary: 1, kb: 0, total: 1, bonus: 4000 },
          { name: 'Aleksey', gitlab: 96, daily_fix: 5, qa: 0, primary: 0, kb: 0, total: 1, bonus: 2000 },
          { name: 'Trian', gitlab: 52, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 22, daily_fix: 5, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 10, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 1, primary: 0, kb: 1, total: 1, bonus: 2000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 6, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 48, daily_fix: 14, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '01.03-07.03.2021', gitlab: 50, daily_fix: 6, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 20, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 52, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 1.5, bonus: 3500 },
          { name: 'Aleksey', gitlab: 1, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 41, daily_fix: 1, qa: 0, primary: 1, kb: 0, total: 1, bonus: 4000 },
          { name: 'Lev', gitlab: 41, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 6, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Theo', gitlab: 174, daily_fix: 3, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '22.02-28.02.2021', gitlab: 60, daily_fix: 10, qa: 2, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 36, daily_fix: 4, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 6, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 66, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 1, bonus: 2000 },
          { name: 'Trian', gitlab: 42, daily_fix: 11, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Lev', gitlab: 10, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 2, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 2, primary: 0, kb: 1, total: 0.5, bonus: 3250 },
          { name: 'Patrick', gitlab: 0, daily_fix: 11, qa: 0, primary: 1, kb: 0, total: 1.5, bonus: 5500 },
          { name: 'Theo', gitlab: 83, daily_fix: 8, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '15.02-21.02.2021', gitlab: 100, daily_fix: 10, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 85, daily_fix: 10, qa: 0, primary: 2, kb: 0, total: 0.75, bonus: 2500 },
          { name: 'Bonyaka', gitlab: 45, daily_fix: 2, qa: 0, primary: 2, kb: 0, total: 0.25, bonus: 1000 },
          { name: 'Aleksey', gitlab: 285, daily_fix: 17, qa: 0, primary: 1, kb: 0, total: 1.75, bonus: 4500 },
          { name: 'Trian', gitlab: 54, daily_fix: 7, qa: 0, primary: 1, kb: 0, total: 0.25, bonus: 1000 },
          { name: 'Lev', gitlab: 87, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, kb: 1, total: 1.5, bonus: 3250 },
          { name: 'Patrick', gitlab: 0, daily_fix: 1, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 67, daily_fix: 2, qa: 0, primary: 2, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '08.02-14.02.2021', gitlab: 100, daily_fix: 10, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 68, daily_fix: 5, qa: 0, primary: 2, kb: 0, total: 0.33, bonus: 1330 },
          { name: 'Bonyaka', gitlab: 53, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 280, daily_fix: 17, qa: 0, primary: 0, kb: 0, total: 2, bonus: 5000 },
          { name: 'Trian', gitlab: 51, daily_fix: 7, qa: 0, primary: 1, kb: 0, total: 0.33, bonus: 1330 },
          { name: 'Lev', gitlab: 83, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 10, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 1, qa: 0, primary: 1, kb: 0, total: 0.33, bonus: 1330 },
          { name: 'Theo', gitlab: 114, daily_fix: 3, qa: 0, primary: 2, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '01.02-07.02.2021', gitlab: 75, daily_fix: 10, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 16, daily_fix: 10, qa: 0, primary: 1, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 55, daily_fix: 13, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 68, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 23, daily_fix: 5, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 106, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, kb: 3, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 9, qa: 0, primary: 2, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 115, daily_fix: 4, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '25.01-31.01.2021', gitlab: 75, daily_fix: 14, qa: 10, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 1, daily_fix: 1, qa: 0, primary: 1, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Bonyaka', gitlab: 61, daily_fix: 10, qa: 0, primary: 0, kb: 0, total: 0, bonus: 2250 },
          { name: 'Aleksey', gitlab: 90, daily_fix: 17, qa: 0, primary: 0, kb: 0, total: 0.83, bonus: 2500 },
          { name: 'Trian', gitlab: 50, daily_fix: 15, qa: 0, primary: 1, kb: 0, total: 0.83, bonus: 0 },
          { name: 'Lev', gitlab: 78, daily_fix: 18, qa: 0, primary: 0, kb: 0, total: 0.83, bonus: 2250 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 14, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 22, primary: 0, kb: 1, total: 1.5, bonus: 3750 },
          { name: 'Patrick', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 99, daily_fix: 14, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '18.01-24.01.2021', gitlab: 90, daily_fix: 14, qa: 10, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 35, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 57, daily_fix: 24, qa: 0, primary: 0, kb: 0, total: 1, bonus: 3000 },
          { name: 'Aleksey', gitlab: 147, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Trian', gitlab: 41, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 140, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 16, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 11, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Patrick', gitlab: 0, daily_fix: 11, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 142, daily_fix: 10, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '28.12-17.01.2021', gitlab: 90, daily_fix: 11, qa: 5, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 59, daily_fix: 13, qa: 0, primary: 0, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Bonyaka', gitlab: 58, daily_fix: 6, qa: 0, primary: 1, kb: 0, total: 1, bonus: 3000 },
          { name: 'Aleksey', gitlab: 202, daily_fix: 11, qa: 0, primary: 0, kb: 0, total: 0.83, bonus: 2250 },
          { name: 'Trian', gitlab: 109, daily_fix: 19, qa: 0, primary: 0, kb: 0, total: 0.83, bonus: 2250 },
          { name: 'Lev', gitlab: 65, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 2, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 147, daily_fix: 10, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '21.12-27.12.2020', gitlab: 90, daily_fix: 12, qa: 8, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 66, daily_fix: 2, qa: 0, primary: 1, kb: 0, total: 1, bonus: 3000 },
          { name: 'Bonyaka', gitlab: 70, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 45, daily_fix: 5, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 126, daily_fix: 18, qa: 0, primary: 0, kb: 0, total: 2, bonus: 5500 },
          { name: 'Lev', gitlab: 8, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 15, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 4, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Patrick', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 100, daily_fix: 5, qa: 0, primary: 0, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '14.12-20.12.2020', gitlab: 80, daily_fix: 15, qa: 5, primary: 1, kb: 1 },
          { name: 'Re', gitlab: 51, daily_fix: 9, qa: 0, primary: 1, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Bonyaka', gitlab: 35, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 107, daily_fix: 21, qa: 0, primary: 0, kb: 0, total: 1.5, bonus: 4250 },
          { name: 'Trian', gitlab: 80, daily_fix: 10, qa: 0, primary: 1, kb: 0, total: 0.83, bonus: 2250 },
          { name: 'Lev', gitlab: 68, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 10, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Patrick', gitlab: 0, daily_fix: 2, qa: 0, primary: 1, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Theo', gitlab: 76, daily_fix: 9, qa: 0, primary: 2, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '07.12-13.12.2020', gitlab: 90, daily_fix: 8, qa: 7, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 43, daily_fix: 10, qa: 0, primary: 1, kb: 0, total: 0.53, bonus: 1600 },
          { name: 'Aleksey', gitlab: 145, daily_fix: 14, qa: 0, primary: 0, kb: 0, total: 1.2, bonus: 3100 },
          { name: 'Trian', gitlab: 66, daily_fix: 9, qa: 0, primary: 1, kb: 0, total: 0.53, bonus: 1600 },
          { name: 'Lev', gitlab: 67, daily_fix: 11, qa: 0, primary: 0, kb: 0, total: 0.2, bonus: 600 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 20, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, kb: 1, total: 0.5, bonus: 1250 },
          { name: 'Patrick', gitlab: 3, daily_fix: 10, qa: 0, primary: 1, kb: 1, total: 1.03, bonus: 2850 },
          { name: 'Theo', gitlab: 195, daily_fix: 26, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '30.11-06.12.2020', gitlab: 90, daily_fix: 8, qa: 7, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 54, daily_fix: 8, qa: 0, primary: 1, kb: 0, total: 0.67, bonus: 2000 },
          { name: 'Bonyaka', gitlab: 25, daily_fix: 1, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 145, daily_fix: 14, qa: 0, primary: 0, kb: 0, total: 1.33, bonus: 3500 },
          { name: 'Trian', gitlab: 67, daily_fix: 6, qa: 0, primary: 1, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Lev', gitlab: 45, daily_fix: 9, qa: 0, primary: 0, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 2, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 11, primary: 0, kb: 0, total: 1, bonus: 2500 },
          { name: 'Patrick', gitlab: 0, daily_fix: 2, qa: 0, primary: 3, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Theo', gitlab: 135, daily_fix: 8, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '23.11-29.11.2020', gitlab: 90, daily_fix: 9, qa: 7, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 100, daily_fix: 7, qa: 0, primary: 2, kb: 0, total: 0.83, bonus: 2250 },
          { name: 'Bonyaka', gitlab: 32, daily_fix: 3, qa: 0, primary: 1, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Aleksey', gitlab: 153, daily_fix: 17, qa: 0, primary: 1, kb: 0, total: 1.83, bonus: 5250 },
          { name: 'Trian', gitlab: 39, daily_fix: 2, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 53, daily_fix: 7, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 7, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 12, primary: 0, kb: 4, total: 1.5, bonus: 3750 },
          { name: 'Patrick', gitlab: 0, daily_fix: 6, qa: 4, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 108, daily_fix: 10, qa: 4, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '16.11-22.11.2020', gitlab: 90, daily_fix: 9, qa: 4, primary: 1, kb: 2 },
          { name: 'Re', gitlab: 7, daily_fix: 0, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 42, daily_fix: 3, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 55, daily_fix: 4, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 60, daily_fix: 9, qa: 0, primary: 1, kb: 0, total: 1.5, bonus: 4500 },
          { name: 'Lev', gitlab: 186, daily_fix: 10, qa: 1, primary: 0, kb: 0, total: 1.5, bonus: 4000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 6, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Patrick', gitlab: 0, daily_fix: 2, qa: 2, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 180, daily_fix: 12, qa: 0, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '09.11-15.11.2020', gitlab: 90, daily_fix: 15, qa: 4, primary: 2, kb: 2 },
          { name: 'Re', gitlab: 30, daily_fix: 8, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 37, daily_fix: 10, qa: 0, primary: 1, kb: 0, total: 0.5, bonus: 1500 },
          { name: 'Aleksey', gitlab: 107, daily_fix: 22, qa: 0, primary: 0, kb: 0, total: 1, bonus: 2750 },
          { name: 'Trian', gitlab: 115, daily_fix: 19, qa: 3, primary: 1, kb: 0, total: 1.5, bonus: 4250 },
          { name: 'Lev', gitlab: 41, daily_fix: 11, qa: 3, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 11, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 8, primary: 0, kb: 2, total: 1.5, bonus: 3750 },
          { name: 'Patrick', gitlab: 0, daily_fix: 0, qa: 2, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 92, daily_fix: 5, qa: 3, primary: 1, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '02.11-08.11.2020', gitlab: 90, daily_fix: 10, qa: 4, primary: 2, kb: 2 },
          { name: 'Re', gitlab: 93, daily_fix: 0, qa: 0, primary: 2, kb: 0, total: 1, bonus: 2750 },
          { name: 'Bonyaka', gitlab: 17, daily_fix: 6, qa: 0, primary: 0, kb: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 127, daily_fix: 13, qa: 2, primary: 2, kb: 0, total: 1.33, bonus: 3750 },
          { name: 'Trian', gitlab: 66, daily_fix: 10, qa: 2, primary: 0, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Lev', gitlab: 34, daily_fix: 10, qa: 3, primary: 0, kb: 0, total: 0.33, bonus: 1000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 7, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 4, primary: 0, kb: 0, total: 0.5, bonus: 1250 },
          { name: 'Patrick', gitlab: 0, daily_fix: 1, qa: 1, primary: 1, kb: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 116, daily_fix: 8, qa: 0, primary: 2, kb: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '26.10-01.11.2020', gitlab: 100, daily_fix: 12, qa: 8, primary: 2 },
          { name: 'Re', gitlab: 53, daily_fix: 5, qa: 0, primary: 1, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 84, daily_fix: 6, qa: 0, primary: 2, total: 1, bonus: 3000 },
          { name: 'Aleksey', gitlab: 121, daily_fix: 20, qa: 0, primary: 1, total: 1, bonus: 2750 },
          { name: 'Lev', gitlab: 36, daily_fix: 9, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 13, primary: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 105, daily_fix: 17, qa: 0, primary: 1, total: 1, bonus: 2750 },
          { name: 'Theo', gitlab: 116, daily_fix: 13, qa: 0, primary: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '19.10-25.10.2020', gitlab: 70, daily_fix: 10, qa: 8, primary: 1 },
          { name: 'Re', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 26, daily_fix: 3, qa: 0, primary: 1, total: 0.5, bonus: 1500 },
          { name: 'Aleksey', gitlab: 94, daily_fix: 5, qa: 0, primary: 1, total: 1, bonus: 2750 },
          { name: 'Lev', gitlab: 60, daily_fix: 11, qa: 0, primary: 0, total: 1, bonus: 3000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 14, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 8, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Trian', gitlab: 72, daily_fix: 2, qa: 0, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Theo', gitlab: 109, daily_fix: 9, qa: 0, primary: 2, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '12.10-18.10.2020', gitlab: 80, daily_fix: 13, qa: 12, primary: 2 },
          { name: 'Re', gitlab: 57, daily_fix: 14, qa: 0, primary: 1, total: 0.83, bonus: 2500 },
          { name: 'Bonyaka', gitlab: 29, daily_fix: 17, qa: 0, primary: 0, total: 0.33, bonus: 1000 },
          { name: 'Aleksey', gitlab: 153, daily_fix: 11, qa: 0, primary: 1, total: 1.5, bonus: 4000 },
          { name: 'Lev', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 16, primary: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 2, primary: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 39, daily_fix: 13, qa: 2, primary: 0, total: 0.33, bonus: 1000 },
          { name: 'Theo', gitlab: 131, daily_fix: 5, qa: 0, primary: 1, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '05.10-11.10.2020', gitlab: 50, daily_fix: 10, qa: 10, primary: 2 },
          { name: 'Re', gitlab: 62, daily_fix: 4, qa: 2, primary: 2, total: 1.5, bonus: 4250 },
          { name: 'Bonyaka', gitlab: 20, daily_fix: 7, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 36, daily_fix: 6, qa: 2, primary: 1, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 0, daily_fix: 2, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 13, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 10, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Trian', gitlab: 54, daily_fix: 12, qa: 0, primary: 0, total: 1.5, bonus: 4250 },
          { name: 'Theo', gitlab: 122, daily_fix: 4, qa: 1, primary: 1, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '28.09-04.10.2020', gitlab: 90, daily_fix: 8, qa: 9, primary: 1 },
          { name: 'Re', gitlab: 82, daily_fix: 11, qa: 0, primary: 1, total: 1.33, bonus: 3700 },
          { name: 'Bonyaka', gitlab: 22, daily_fix: 3, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 181, daily_fix: 8, qa: 6, primary: 0, total: 1.33, bonus: 3700 },
          { name: 'Lev', gitlab: 57, daily_fix: 9, qa: 3, primary: 0, total: 0.33, bonus: 1200 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 16, primary: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 6, primary: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 46, daily_fix: 2, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Theo', gitlab: 83, daily_fix: 3, qa: 10, primary: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '21.09-27.09.2020', gitlab: 75, daily_fix: 8, qa: 9, primary: 1 },
          { name: 'Re', gitlab: 92, daily_fix: 2, qa: 1, primary: 1, total: 1.5, bonus: 3450 },
          { name: 'Bonyaka', gitlab: 17, daily_fix: 8, qa: 0, primary: 1, total: 0.83, bonus: 1950 },
          { name: 'Aleksey', gitlab: 29, daily_fix: 4, qa: 5, primary: 0, total: 0, bonus: 0 },
          { name: 'Lev', gitlab: 32, daily_fix: 15, qa: 1, primary: 0, total: 0.33, bonus: 700 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 6, primary: 0, total: 0, bonus: 0 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 12, primary: 0, total: 1, bonus: 2200 },
          { name: 'Trian', gitlab: 50, daily_fix: 8, qa: 0, primary: 0, total: 0.33, bonus: 700 },
          { name: 'Theo', gitlab: 116, daily_fix: 4, qa: 2, primary: 2, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '14.09-20.09.2020', gitlab: 75, daily_fix: 10, qa: 9, primary: 1 },
          { name: 'Re', gitlab: 102, daily_fix: 3, qa: 1, primary: 1, total: 1.5, bonus: 3750 },
          { name: 'Bonyaka', gitlab: 34, daily_fix: 6, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 0, daily_fix: 1, qa: 0, primary: 1, total: 0.5, bonus: 1250 },
          { name: 'Lev', gitlab: 40, daily_fix: 9, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 12, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 9, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Trian', gitlab: 48, daily_fix: 14, qa: 0, primary: 0, total: 1, bonus: 2500 },
          { name: 'Theo', gitlab: 222, daily_fix: 18, qa: 2, primary: 0, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '07.09-13.09.2020', gitlab: 75, daily_fix: 10, qa: 5, primary: 1 },
          { name: 'Re', gitlab: 72, daily_fix: 13, qa: 0, primary: 1, total: 0.83, bonus: 2000 },
          { name: 'Bonyaka', gitlab: 87, daily_fix: 18, qa: 1, primary: 0, total: 1.33, bonus: 3500 },
          { name: 'Aleksey', gitlab: 61, daily_fix: 3, qa: 0, primary: 1, total: 0.5, bonus: 1000 },
          { name: 'Lev', gitlab: 45, daily_fix: 8, qa: 1, primary: 0, total: 0, bonus: 0 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 7, primary: 0, total: 0.5, bonus: 1250 },
          { name: 'Trian', gitlab: 39, daily_fix: 10, qa: 5, primary: 0, total: 0.83, bonus: 2250 },
          { name: 'Theo', gitlab: 78, daily_fix: 8, qa: 1, primary: 1, skip: true, total: 0, bonus: 0 }
        ],
        [
          { name: '31.08-06.09.2020', gitlab: 75, daily_fix: 8, qa: 10 },
          { name: 'Re', gitlab: 37, daily_fix: 4, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Bonyaka', gitlab: 48, daily_fix: 4, qa: 0, primary: 0, total: 0, bonus: 0 },
          { name: 'Aleksey', gitlab: 122, daily_fix: 12, qa: 0, primary: 0, total: 1.33, bonus: 4200 },
          { name: 'Lev', gitlab: 50, daily_fix: 8, qa: 0, primary: 0, total: 0.33, bonus: 1700 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 16, primary: 0, total: 1, bonus: 2500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 5, primary: 0, total: 0, bonus: 0 },
          { name: 'Trian', gitlab: 55, daily_fix: 9, qa: 7, primary: 0, total: 0.33, bonus: 1700 },
          { name: 'Theo', gitlab: 99, daily_fix: 8, qa: 0, primary: 0, skip: true, total: 0, bonus: 0 }
        ]
      ]
    };
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
          <h2>History</h2>
        </div>

        {this.state.items.map((items) => {
          return <PeriodTable key={items[0].name} items={items} total={items[0]} />;
        })}
      </>
    );
  }
}

export default History;
