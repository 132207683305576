import React from 'react';
import TotalTable from '../total_table';

class BonusHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        [
          { daily_fix: 123, primary: 16, qa: 18, gitlab: 874, kb: 11, total: 19, bonus: 53000, name: 'July 2021' },
          { daily_fix: 2, primary: 0, qa: 5, gitlab: 0, kb: 6, total: 5, bonus: 10500, name: 'Maria' },
          { daily_fix: 21, primary: 5, qa: 0, gitlab: 0, kb: 2, total: 3, bonus: 10500, name: 'Patrick' },
          { daily_fix: 16, primary: 0, qa: 0, gitlab: 274, kb: 0, total: 4, bonus: 9500, name: 'Aleksey' },
          { daily_fix: 0, primary: 0, qa: 13, gitlab: 0, kb: 0, total: 2, bonus: 7500, name: 'Xander' },
          { daily_fix: 9, primary: 2, qa: 0, gitlab: 139, kb: 0, total: 2, bonus: 5500, name: 'Re' },
          { daily_fix: 4, primary: 5, qa: 0, gitlab: 31, kb: 0, total: 1, bonus: 4000, name: 'Bonyaka' },
          { daily_fix: 27, primary: 1, qa: 0, gitlab: 173, kb: 0, total: 1.5, bonus: 4000, name: 'Trian' },
          { daily_fix: 24, primary: 1, qa: 0, gitlab: 0, kb: 0, total: 0.5, bonus: 1500, name: 'Lev' },
          { daily_fix: 20, primary: 2, qa: 0, gitlab: 257, kb: 3, total: 0, bonus: 0, name: 'Theo' }
        ],
        [
          { daily_fix: 131, primary: 12, qa: 31, gitlab: 905, kb: 12, total: 20.99, bonus: 55500, name: 'June 2021' },
          { daily_fix: 15, primary: 2, qa: 0, gitlab: 250, kb: 0, total: 5, bonus: 12000, name: 'Re' },
          { daily_fix: 0, primary: 0, qa: 16, gitlab: 0, kb: 12, total: 5.5, bonus: 11750, name: 'Maria' },
          { daily_fix: 22, primary: 2, qa: 0, gitlab: 219, kb: 0, total: 3.33, bonus: 8500, name: 'Trian' },
          { daily_fix: 29, primary: 2, qa: 0, gitlab: 127, kb: 0, total: 2.33, bonus: 8000, name: 'Aleksey' },
          { daily_fix: 21, primary: 3, qa: 0, gitlab: 0, kb: 0, total: 1.83, bonus: 6000, name: 'Patrick' },
          { daily_fix: 6, primary: 2, qa: 0, gitlab: 33, kb: 0, total: 1, bonus: 4000, name: 'Bonyaka' },
          { daily_fix: 0, primary: 0, qa: 15, gitlab: 0, kb: 0, total: 1.5, bonus: 3750, name: 'Xander' },
          { daily_fix: 16, primary: 0, qa: 0, gitlab: 0, kb: 0, total: 0.5, bonus: 1500, name: 'Lev' },
          { daily_fix: 22, primary: 1, qa: 0, gitlab: 276, kb: 0, total: 0, bonus: 0, name: 'Theo' }
        ],
        [
          { gitlab: 1001, daily_fix: 117, qa: 31, kb: 1, primary: 12, bonus: 61010, name: 'May 2021' },
          { gitlab: 240, daily_fix: 34, qa: 0, kb: 0, primary: 1, bonus: 15670, name: 'Trian' },
          { gitlab: 0, daily_fix: 0, qa: 25, kb: 0, primary: 0, bonus: 9500, name: 'Xander' },
          { gitlab: 223, daily_fix: 2, qa: 0, kb: 0, primary: 3, bonus: 7670, name: 'Aleksey' },
          { gitlab: 193, daily_fix: 5, qa: 0, kb: 0, primary: 2, bonus: 6670, name: 'Re' },
          { gitlab: 0, daily_fix: 0, qa: 6, kb: 1, primary: 1, bonus: 6500, name: 'Maria' },
          { gitlab: 0, daily_fix: 20, qa: 0, kb: 0, primary: 2, bonus: 6500, name: 'Patrick' },
          { gitlab: 65, daily_fix: 10, qa: 0, kb: 0, primary: 1, bonus: 5000, name: 'Bonyaka' },
          { gitlab: 8, daily_fix: 21, qa: 0, kb: 0, primary: 0, bonus: 3500, name: 'Lev' },
          { gitlab: 272, daily_fix: 25, qa: 0, kb: 0, primary: 2, bonus: 0, name: 'Theo' }
        ],
        [
          { name: 'April 2021', gitlab: 1942, daily_fix: 198, qa: 75, kb: 4, primary: 15, bonus: 59510 },
          { name: 'Re', gitlab: 402, daily_fix: 27, qa: 0, kb: 0, primary: 3, bonus: 10420 },
          { name: 'Bonyaka', gitlab: 235, daily_fix: 37, qa: 0, kb: 0, primary: 4, bonus: 10250 },
          { name: 'Aleksey', gitlab: 314, daily_fix: 23, qa: 0, kb: 0, primary: 2, bonus: 5250 },
          { name: 'Trian', gitlab: 378, daily_fix: 21, qa: 0, kb: 0, primary: 5, bonus: 7670 },
          { name: 'Lev', gitlab: 354, daily_fix: 34, qa: 0, kb: 0, primary: 0, bonus: 5170 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 64, kb: 0, primary: 0, bonus: 12500 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 11, kb: 4, primary: 0, bonus: 6000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 25, qa: 0, kb: 0, primary: 0, bonus: 2250 },
          { name: 'Theo', gitlab: 259, daily_fix: 31, qa: 0, kb: 0, primary: 1, bonus: 0 }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'March 2021', gitlab: 1887, daily_fix: 237, qa: 61, kb: 4, primary: 9, bonus: 64500 },
          { gitlab: 263, daily_fix: 35, qa: 0, kb: 0, primary: 2, bonus: 10750, name: 'Re' },
          { gitlab: 202, daily_fix: 35, qa: 0, kb: 0, primary: 1, bonus: 9000, name: 'Bonyaka' },
          { gitlab: 468, daily_fix: 40, qa: 0, kb: 0, primary: 1, bonus: 12000, name: 'Aleksey' },
          { gitlab: 270, daily_fix: 19, qa: 0, kb: 0, primary: 2, bonus: 8000, name: 'Trian' },
          { gitlab: 249, daily_fix: 33, qa: 0, kb: 0, primary: 0, bonus: 2250, name: 'Lev' },
          { gitlab: 0, daily_fix: 0, qa: 48, kb: 0, primary: 0, bonus: 13500, name: 'Xander' },
          { gitlab: 0, daily_fix: 0, qa: 13, kb: 4, primary: 0, bonus: 6000, name: 'Maria' },
          { gitlab: 0, daily_fix: 32, qa: 0, kb: 0, primary: 0, bonus: 3000, name: 'Patrick' },
          { gitlab: 435, daily_fix: 43, qa: 0, kb: 0, primary: 3, bonus: 0, name: 'Theo' }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'February 2021', gitlab: 1898, daily_fix: 187, qa: 38, kb: 5, primary: 20, bonus: 38490 },
          { gitlab: 205, daily_fix: 29, qa: 0, kb: 0, primary: 5, bonus: 3830, name: 'Re' },
          { gitlab: 159, daily_fix: 22, qa: 0, kb: 0, primary: 2, bonus: 1000, name: 'Bonyaka' },
          { gitlab: 699, daily_fix: 40, qa: 0, kb: 0, primary: 1, bonus: 11500, name: 'Aleksey' },
          { gitlab: 170, daily_fix: 30, qa: 0, kb: 0, primary: 2, bonus: 3830, name: 'Trian' },
          { gitlab: 286, daily_fix: 27, qa: 0, kb: 0, primary: 0, bonus: 0, name: 'Lev' },
          { gitlab: 0, daily_fix: 0, qa: 26, kb: 0, primary: 0, bonus: 5000, name: 'Xander' },
          { gitlab: 0, daily_fix: 0, qa: 12, kb: 5, primary: 0, bonus: 6500, name: 'Maria' },
          { gitlab: 0, daily_fix: 22, qa: 0, kb: 0, primary: 4, bonus: 6830, name: 'Patrick' },
          { gitlab: 379, daily_fix: 17, qa: 0, kb: 0, primary: 6, bonus: 0, name: 'Theo' }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'January 2021', gitlab: 1581, daily_fix: 216, qa: 74, kb: 1, primary: 3, bonus: 32500 },
          { gitlab: 95, daily_fix: 17, qa: 0, kb: 0, primary: 1, bonus: 2500, name: 'Re' },
          { gitlab: 176, daily_fix: 40, qa: 0, kb: 0, primary: 1, bonus: 6000, name: 'Bonyaka' },
          { gitlab: 439, daily_fix: 35, qa: 0, kb: 0, primary: 0, bonus: 5750, name: 'Aleksey' },
          { gitlab: 200, daily_fix: 42, qa: 0, kb: 0, primary: 1, bonus: 4750, name: 'Trian' },
          { gitlab: 283, daily_fix: 35, qa: 0, kb: 0, primary: 0, bonus: 3500, name: 'Lev' },
          { gitlab: 0, daily_fix: 0, qa: 39, kb: 0, primary: 0, bonus: 5000, name: 'Xander' },
          { gitlab: 0, daily_fix: 0, qa: 35, kb: 1, primary: 0, bonus: 5000, name: 'Maria' },
          { gitlab: 0, daily_fix: 13, qa: 0, kb: 0, primary: 0, bonus: 0, name: 'Patrick' },
          { gitlab: 388, daily_fix: 34, qa: 0, kb: 0, primary: 0, bonus: 0, name: 'Theo' }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'December 2020', gitlab: 1822, daily_fix: 231, qa: 72, primary: 16, kb: 2, bonus: 46500 },
          { name: 'Re', gitlab: 171, daily_fix: 19, qa: 0, primary: 3, kb: 0, bonus: 6000 },
          { name: 'Bonyaka', gitlab: 173, daily_fix: 23, qa: 0, primary: 1, kb: 0, bonus: 1600 },
          { name: 'Aleksey', gitlab: 442, daily_fix: 54, qa: 0, primary: 0, kb: 0, bonus: 10850 },
          { name: 'Trian', gitlab: 339, daily_fix: 43, qa: 0, primary: 3, kb: 0, bonus: 10350 },
          { name: 'Lev', gitlab: 188, daily_fix: 30, qa: 0, primary: 0, kb: 0, bonus: 1600 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 42, primary: 0, kb: 0, bonus: 6250 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 30, primary: 0, kb: 1, bonus: 5000 },
          { name: 'Patrick', gitlab: 3, daily_fix: 14, qa: 0, primary: 5, kb: 1, bonus: 4850 },
          { name: 'Theo', gitlab: 506, daily_fix: 48, qa: 0, primary: 4, kb: 0, bonus: 0 }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'November 2020', gitlab: 1890, daily_fix: 215, qa: 94, primary: 17, kb: 6, bonus: 49000 },
          { name: 'Re', gitlab: 230, daily_fix: 15, qa: 0, primary: 4, kb: 0, bonus: 5000 },
          { name: 'Bonyaka', gitlab: 128, daily_fix: 22, qa: 0, primary: 2, kb: 0, bonus: 2500 },
          { name: 'Aleksey', gitlab: 442, daily_fix: 56, qa: 2, primary: 3, kb: 0, bonus: 11750 },
          { name: 'Trian', gitlab: 280, daily_fix: 40, qa: 5, primary: 2, kb: 0, bonus: 9750 },
          { name: 'Lev', gitlab: 314, daily_fix: 38, qa: 7, primary: 0, kb: 0, bonus: 5000 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 31, primary: 0, kb: 0, bonus: 5000 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 33, primary: 0, kb: 6, bonus: 10000 },
          { name: 'Patrick', gitlab: 0, daily_fix: 9, qa: 9, primary: 1, kb: 0, bonus: 0 },
          { name: 'Theo', gitlab: 496, daily_fix: 35, qa: 7, primary: 5, kb: 0, bonus: 0 }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'October 2020', gitlab: 2050, daily_fix: 231, qa: 124, primary: 17, bonus: 55100 },
          { name: 'Re', gitlab: 254, daily_fix: 34, qa: 2, primary: 5, bonus: 10450 },
          { name: 'Bonyaka', gitlab: 181, daily_fix: 36, qa: 0, primary: 3, bonus: 5500 },
          { name: 'Aleksey', gitlab: 585, daily_fix: 50, qa: 8, primary: 4, bonus: 13200 },
          { name: 'Lev', gitlab: 153, daily_fix: 31, qa: 3, primary: 0, bonus: 4200 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 72, primary: 0, bonus: 10000 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 26, primary: 0, bonus: 2500 },
          { name: 'Trian', gitlab: 316, daily_fix: 46, qa: 2, primary: 1, bonus: 9250 },
          { name: 'Theo', gitlab: 561, daily_fix: 34, qa: 11, primary: 4, bonus: 0 }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          }),
        [
          { name: 'September 2020', gitlab: 2814, daily_fix: 353, qa: 161, primary: 14, bonus: 39100 },
          { name: 'Re', gitlab: 514, daily_fix: 42, qa: 3, primary: 5, bonus: 9200 },
          { name: 'Bonyaka', gitlab: 355, daily_fix: 64, qa: 2, primary: 1, bonus: 5450 },
          { name: 'Aleksey', gitlab: 395, daily_fix: 36, qa: 5, primary: 4, bonus: 6450 },
          { name: 'Lev', gitlab: 302, daily_fix: 65, qa: 3, primary: 0, bonus: 2400 },
          { name: 'Xander', gitlab: 0, daily_fix: 0, qa: 62, primary: 0, bonus: 3750 },
          { name: 'Maria', gitlab: 0, daily_fix: 0, qa: 54, primary: 0, bonus: 4700 },
          { name: 'Trian', gitlab: 334, daily_fix: 74, qa: 24, primary: 0, bonus: 7150 },
          { name: 'Theo', gitlab: 914, daily_fix: 72, qa: 8, primary: 4, skip: true, bonus: 0 }
        ]
          .sort((a, b) => b.bonus - a.bonus)
          .map((v) => {
            delete v.total;
            delete v.penalty;

            return v;
          })
      ]
    };
  }
  render() {
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
          <h2>Bonus history</h2>
        </div>

        {this.state.items.map((items) => {
          return <TotalTable key={items[0].name} items={items} />;
        })}
      </>
    );
  }
}

export default BonusHistory;
