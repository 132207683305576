import React from 'react';
import './style.css';
import { PeriodItemType } from './types';

function hasPrize(value, max, limit, skip) {
  return !skip && value >= Math.max(max, limit);
}

class PeriodItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prizes: {
        ...['primary', 'daily_fix', 'qa', 'kb', 'gitlab'].reduce((res, name) => {
          res[name] = hasPrize(
            this.props[name],
            this.props.max[name],
            this.props.limit ? this.props.limit[name] : 0,
            this.props.skip
          );

          return res;
        }, {})
      }
    };
  }

  render() {
    return (
      <tr>
        <td>{this.props.name}</td>
        <td className={this.state.prizes.primary ? 'prize' : 'no-prize'}>{this.props.primary || ''}</td>
        <td className={this.state.prizes.daily_fix ? 'prize' : 'no-prize'}>{this.props.daily_fix || ''}</td>
        <td className={this.state.prizes.qa ? 'prize' : 'no-prize'}>{this.props.qa || ''}</td>
        <td className={this.state.prizes.kb ? 'prize' : 'no-prize'}>{this.props.kb || ''}</td>
        <td className={this.state.prizes.gitlab ? 'prize' : 'no-prize'}>{this.props.gitlab || ''}</td>
        <td>{this.props.penalty || ''}</td>
        <td>{this.props.total || ''}</td>
        <td>{this.props.bonus || ''}</td>
      </tr>
    );
  }
}

PeriodItem.propTypes = PeriodItemType;

export default PeriodItem;
