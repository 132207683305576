import PropTypes from 'prop-types';

const TotalItemType = {
  name: PropTypes.string,
  primary: PropTypes.number,
  daily_fix: PropTypes.number,
  qa: PropTypes.number,
  kb: PropTypes.number,
  gitlab: PropTypes.number,
  bonus: PropTypes.number
};

export { TotalItemType };
