import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import History from './history';
import LastWeek from './last-week';
import BonusHistory from './bonus_history';

class ContentRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/">
          <LastWeek />
        </Route>
        <Route path="/history">
          <History />
        </Route>
        <Route path="/bonus_history">
          <BonusHistory />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
}

export default ContentRouter;
