import React from 'react';
import { TotalItemType } from './types';

class TotalItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr>
        <td>{this.props.name}</td>
        <td>{this.props.primary || ''}</td>
        <td>{this.props.daily_fix || ''}</td>
        <td>{this.props.qa || ''}</td>
        <td>{this.props.kb || ''}</td>
        <td>{this.props.gitlab || ''}</td>
        <td>{this.props.bonus || ''}</td>
      </tr>
    );
  }
}

TotalItem.propTypes = TotalItemType;

export default TotalItem;
