import React from 'react';
import PeriodTable from '../period_table';

class LastWeek extends React.Component {
  constructor(props) {
    super(props);

    const limits = {
      gitlab: 50,
      daily_fix: 6,
      qa: 5,
      primary: 1,
      kb: 1
    };

    this.state = {
      items: this.calculate(
        [
          { name: 'Re', daily_fix: 0, primary: 0, qa: 0, gitlab: 52, kb: 0 },
          {
            name: 'Bonyaka',
            daily_fix: 0,
            primary: 0,
            qa: 0,
            gitlab: 0,
            kb: 0
          },
          {
            name: 'Aleksey',
            daily_fix: 9,
            primary: 1,
            qa: 0,
            gitlab: 32,
            kb: 0
          },
          { name: 'Trian', daily_fix: 4, primary: 0, qa: 0, gitlab: 56, kb: 0 },
          { name: 'Xander', daily_fix: 0, primary: 0, qa: 3, gitlab: 0, kb: 0 },
          { name: 'Maria', daily_fix: 0, primary: 0, qa: 9, gitlab: 0, kb: 1 },
          {
            name: 'Patrick',
            daily_fix: 0,
            primary: 1,
            qa: 0,
            gitlab: 0,
            kb: 0
          },
          {
            name: 'Theo',
            daily_fix: 5,
            primary: 1,
            qa: 0,
            gitlab: 93,
            kb: 0,
            skip: true
          }
        ],
        limits
      ),
      limits
    };
  }

  calculate(items, fieldLimits) {
    const fields = ['gitlab', 'daily_fix', 'qa', 'primary', 'kb'];
    // const totalBonus = 10000;

    const bonus = {
      gitlab: 2000,
      daily_fix: 3000,
      qa: 2500,
      primary: 4000,
      kb: 2000
    };

    const counts = {};

    const total = {
      name: '23.08-29.08.2021',
      ...items.reduce((obj, item) => {
        if (item.skip) {
          return obj;
        }

        for (let field of fields) {
          obj[field] = fieldLimits[field];

          if (item[field] <= 0) {
            continue;
          }

          const limitValue = fieldLimits[field];
          const itemValue = item[field];

          if (itemValue >= limitValue) {
            counts[field] = (counts[field] || 0) + 1;
          }
        }

        return obj;
      }, {})
    };

    let totalAgg = {
      bonus: 0,
      total: 0
    };

    const res = [total].concat(
      items.map((item) => {
        item.total = 0;
        item.bonus = 0;

        if (item.skip) {
          return item;
        }

        for (let field of fields) {
          if (item[field] > 0 && item[field] >= fieldLimits[field]) {
            item.total += 1 / counts[field];
            item.bonus += bonus[field] * (1 / counts[field]);
          }
        }

        if (item.penalty !== undefined) {
          item.bonus -= item.penalty;
          totalAgg.penalty = (totalAgg.penalty || 0) + item.penalty;
        }

        item.total = Math.round(item.total * 100) / 100;
        const bonusValue = item.bonus / 10;
        item.bonus = Math.round(Math.ceil(bonusValue / 5) * 5) * 10;

        totalAgg.bonus += item.bonus;
        totalAgg.total += item.total;

        return item;
      })
    );

    Object.assign(res[0], totalAgg);

    // console.log(JSON.stringify(res));

    return res;
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
          <h2>Last Week</h2>
        </div>

        <PeriodTable items={this.state.items} total={this.state.items[0]} limit={this.state.limits} />
      </>
    );
  }
}

export default LastWeek;
