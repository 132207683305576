import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Navbar } from 'react-bootstrap';

class TopBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Navbar bg="dark" variant="dark" sticky="top">
        <Navbar.Brand>{this.props.title}</Navbar.Brand>
        <Nav className="ml-md-auto">
          <Nav.Link onClick={this.props.signOut}>Sign out</Nav.Link>
        </Nav>
      </Navbar>
    );
  }
}

TopBar.propTypes = {
  title: PropTypes.string,
  signOut: PropTypes.func
};

export default TopBar;
